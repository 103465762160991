<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="advantages" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Header"
                  v-model="main.intro.header"
                  :messages="['The recommended number of characters is 15']"
                />

              </div>
              <div class="flex xs12" v-if="tabValue === 1">
                <va-button @click="$router.push({ name: 'bgk-page-report-edit' })">Add Report</va-button>
                <va-data-table
                  :fields="tabFields"
                  :data="items"
                  no-pagination
                >

                  <template slot="toggle" slot-scope="props">
                    <va-toggle
                      small
                      v-on:input="togglePublished(props.rowData.id)"
                      v-model="props.rowData.published"
                    />
                  </template>

                  <template slot="translations" slot-scope="props">
                    <va-badge color="info" :outline="!translation.status" v-for="(translation, index) in props.rowData.trans" :key="index">{{ translation.locale }}</va-badge>
                  </template>

                  <template slot="actions" slot-scope="props">
                    <va-button
                      small
                      color="info"
                      :to="{ name: 'bgk-page-report-edit', params: { id: props.rowData.id } }"
                    >
                      Редагувати
                    </va-button>
                    <va-button
                      small
                      @click="remove(props.rowData.id)"
                      color="danger"
                      class="ma-0">
                      Видалити
                    </va-button>
                  </template>
                </va-data-table>
              </div>
              <va-button @click="submit()">Save</va-button>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
import Vue from 'vue'

export default {
  name: 'grid',
  data () {
    return {
      main: {
        intro: {
          desc: null,
          header: null,
          img: null,
        },
      },
      items: null,

      imgIntroExisting: null,
      imageNew: null,
      img: [],

      errors: [],

      tabTitles: ['Main', 'Reports'],
      tabValue: 0,
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    togglePublished (id) {
      axios.put(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/reports/${id}`, {
        published: !!this.items.find((item) => item.id === id).published,
      })
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    submit () {
      this.main.intro.image = this.imageNew
      const data = {
        main: this.main,
      }
      axios.put(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/pages/reports`, data)
        .then(response => {
          this.$router.push({ name: 'bgk-page-report' })
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/pages/reports`)
        .then(response => {
          this.main = response.data.main
          this.items = response.data.items
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    remove (id) {
      if (confirm('Дійсно видалити?')) {
        axios.delete(`${process.env.VUE_APP_BGK_API_URL}/${this.locale}/admin/reports/${id}`)
          .then(response => {
            const idx = this.items.findIndex(u => u.id === id)
            this.items.splice(idx, 1)
            this.showToast('Success')
          })
          .catch(error => {
            console.log(error)
            this.showToast('Error')
          })
      }
    },
  },
  computed: {
    tabFields () {
      return [{
        name: 'id',
        title: 'Key',
        width: '10%',
      },
      {
        name: 'name',
        title: 'Name',
        width: '50%',
      },
      {
        title: 'Рік',
        name: 'year',
        width: '10%',
      },
      {
        title: 'Опубліковано',
        name: '__slot:toggle',
        width: '10%',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
